<template>
    <v-row v-if="dadosProjeto.projeto">
        <v-snackbar color="green darken-3" v-model="snackbar.status" :timeout="snackbar.timeout" dark>
            <span class="text-caption font-weight-bold text-uppercase">{{snackbar.text}}</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    class="font-weight-bold"
                    text
                    v-bind="attrs"
                    @click="snackbar.status = false"
                >Fechar</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="dialog.status" max-width="575" persistent>
            <CardDialog :dialog="dialog" />
        </v-dialog>      <!-- DIALOG ERRO  -->
        <v-dialog v-model="dialogDetalhe" width="100%" max-width="380px"> <!-- Detalhes Tarefas-->
            <v-card>
                <v-card-title class="primary px-4 py-3">
                    <span style="color: #FFF;">Detalhes Tarefa</span>
                    <v-spacer/>
                    <small style="color: #FFF;" class="pr-4" v-text="`Nº ${tempItem.id}`"></small>
                    <v-icon dark @click="dialogDetalhe = false, tempItem = {}">mdi-window-close</v-icon>
                </v-card-title>
                <v-list-item two-line>
                    <v-list-item-title class="text-body-1 text-truncate">
                        <v-icon class="mr-1 mb-1">mdi-calendar-clock</v-icon>Data de cadastro
                        <v-chip class="float-right pa-3" small>{{tempItem.dhcadastro}}</v-chip>
                    </v-list-item-title>
                </v-list-item> <!-- DATA CADASTRO  -->
                <v-list-item two-line>
                    <v-list-item-title>
                        <v-icon class="mr-1 mb-1">mdi-account</v-icon>Solicidado por<br/>
                        <!--                        <span class="text-truncate" style="font-size: 12px">{{tempItem.dhcadastro}}</span>-->
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-1 text-truncate">
                        <v-chip class="float-right pa-3" small>{{tempItem.usuariosolicitacao}}</v-chip>
                    </v-list-item-subtitle>
                </v-list-item> <!-- SOLICITADO POR -->
                <v-list-item two-line>
                    <v-list-item-title class="text-body-1 text-truncate">
                        <v-icon class="mr-1 mb-1">mdi-timer</v-icon>Prazo de entrega
                        <v-chip class="float-right pa-3" small>{{tempItem.tempomedio != null ? tempItem.tempomedio : 'Não definido'}}</v-chip>
                    </v-list-item-title>
                </v-list-item> <!-- PREVISÃO  -->
                <v-list-item two-line v-if="tempItem.dhconcluido">
                    <v-list-item-title class="text-body-1 text-truncate">
                        <v-icon class="mr-1 mb-1">mdi-account-check</v-icon>Concluída por<br/>
                        <!--                        <span class="text-truncate" style="font-size: 12px">{{tempItem.dhconcluido}}</span>-->
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-body-1 text-truncate">
                        <v-chip class="float-right pa-3" small>{{tempItem.usuarioconclusao}}</v-chip>
                    </v-list-item-subtitle>
                </v-list-item> <!-- CONCLUÍDA POR  -->
                <v-list-item two-line v-if="tempItem.dhconcluido">
                    <v-list-item-title class="text-body-1 text-truncate">
                        <v-icon class="mr-1 mb-1">mdi-calendar-check</v-icon>Data de conclusão
                        <v-chip class="float-right pa-3" small>{{tempItem.dhconcluido}}</v-chip>
                    </v-list-item-title>
                </v-list-item> <!-- DATA CONCLUIDO  -->
                <v-list-item two-line v-if="tempItem.dhvalidacao">
                    <v-list-item-title class="text-body-1 text-truncate">
                        <v-icon class="mr-1 mb-1">mdi-calendar-account</v-icon>Data de validação
                        <v-chip class="float-right pa-3" small>{{tempItem.dhvalidacao}}</v-chip>
                    </v-list-item-title>
                </v-list-item> <!-- DATA VALIDACAO  -->
            </v-card>
        </v-dialog>  <!-- DIALOG DETALHES  -->
        <v-dialog v-model="dialogAdicionar" max-width="800px">
            <v-card>
                <v-card-title class="primary">
                    <span style="color: #FFF;" class="text-h5">Nova Tarefa</span>
                </v-card-title>
                <v-card-text class="pt-2">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <div class="text-body-1 font-weight-bold">Tarefa</div>
                            <v-text-field
                                hide-details
                                outlined
                                dense
                                v-model="tempItem.tarefa"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <div class="text-body-1 font-weight-bold">Descrição</div>
                            <v-textarea
                                hide-details
                                outlined
                                dense
                                v-model="tempItem.descricao"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <div class="text-body-1 font-weight-bold">Solicitante</div>
                            <v-autocomplete
                                hide-details
                                v-model="tempItem.idusuariosolicitacao"
                                outlined
                                dense
                                :items="usuarios.lista"
                                :loading="carregando"
                                item-text="usuario"
                                item-value="idusuario"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="pb-0 px-0">
                            <div class="text-body-1 font-weight-bold pl-3 pb-1">Informar prazo de entrega</div>
                            <v-switch dense class="ml-3 my-0 py-0" color="primary" v-model="tempItem.informaTempo" hide-details></v-switch>
                        </v-col>
                        <v-col cols="12" class="pb-0" v-if="tempItem.informaTempo">
                            <div class="text-body-1 font-weight-bold">Data prevista</div>
                            <InputDatePicker
                                :disabled="carregando"
                                v-model="tempItem.tempomedio"
                                :outlined="true"
                                :dense="true"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogAdicionar = false">Cancelar</v-btn>
                    <v-btn color="primary" text @click="adicionarTarefas()">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>             <!-- DIALOG ADICIONAR TAREFA  -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h6">Tem que certeza que deseja excluir essa tarefa?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogDelete = false, tempItem = {}">Cancelar</v-btn>
                    <v-btn color="primary" text @click="deletarTarefa(tempItem), dialogDelete = false">SIM</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>                <!-- DIALOG DELETAR -->
        <v-dialog v-model="dialogArquivoNovo" persistent max-width="575">
            <v-card min-width="360">
                <v-card-title>Novo arquivo</v-card-title>
                <v-divider />
                <v-card-text class="pt-5">
                    <v-file-input
                        counter
                        multiple
                        show-size
                        outlined
                        dense
                        prepend-icon
                        prepend-inner-icon="mdi-paperclip"
                        @change="adicionarArquivo"
                    ></v-file-input>
                </v-card-text>
                <v-divider />
                <v-card-actions class="pa-4">
                    <v-spacer />
                    <v-btn color="error" elevation="0" @click="dialogArquivoNovo = false">Cancelar</v-btn>
                    <v-btn color="primary" elevation="0" @click="arquivoNovo()">Enviar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> <!-- DIALOG ADICIONAR ARQUIVO -->

        <v-col cols="12" lg="7" order="1" order-lg="1">
            <v-card>
                <v-card-title>
                    Tarefas
                    <v-spacer></v-spacer>
                    <v-btn color="primary" elevation="0" dark @click="dialogAdicionar = true, tempItem = {}">Adicionar Tarefa</v-btn>
                </v-card-title>
                <v-divider/>
                <v-data-table
                    dense
                    :headers="[
                        { text: 'N°', value: 'id', sortable: false, align: 'start', class:'pr-2' },
                        { text: 'Tarefa', value: 'tarefa', sortable: false, class:'px-2'  },
                        { text: 'Descrição', value: 'descricao', sortable: false, class:'px-2'  },
                        { text: 'Concluida', value: 'concluida', sortable: false, align: 'center', class:'px-2'  },
                        { text: 'Validada', value: 'validada', sortable: false, align: 'center', class:'px-2'  },
                        { text: 'Ações', value: 'actions', sortable: false, align: 'center', class:'pl-2' },
                    ]"
                    :items="dadosTarefa"
                    mobile-breakpoint="0"
                    sort-by="id"
                    :sort-desc="false"
                    class="elevation-1"
                >
                    <template v-slot:item="{ item }">
                        <tr :class=" item.concluida && item.validada ? 'light-blue lighten-5' : 'none'">
                            <td class="pr-2">{{ item.id }}</td>
                            <td class="px-2">{{ item.tarefa }}</td>
                            <td class="px-2 text-justify">{{ item.descricao }}</td>
                            <td class="px-2 text-center" width="75px">
                                <v-checkbox
                                    class="shrink d-inline-flex mb-4 ml-1"
                                    v-model="item.concluida"
                                    color="primary"
                                    hide-details
                                    @click="atualizarTarefa(item)"
                                ></v-checkbox>
                            </td>
                            <td class="px-2 text-center" width="75px">
                                <v-checkbox
                                    class="shrink d-inline-flex mb-4 ml-1"
                                    v-model="item.validada"
                                    color="primary"
                                    hide-details
                                    @click="atualizarTarefa(item)"
                                ></v-checkbox>
                            </td>
                            <td class="pl-2 text-center d-none d-sm-table-cell" width="100px">
                                <v-btn @click="exibirDetalhes(item)" x-small dark class="primary pa-0">
                                    <v-icon small>mdi-magnify</v-icon>
                                </v-btn>
                                <v-btn @click="confirmaDelecao(item)" x-small dark class="ml-1 red darken-3">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </td>

                            <td class="pl-2 text-center d-none d-table-cell d-sm-none" width="70px">
                                <div class="text-center" >
                                    <v-menu offset-y >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                x-small
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon small>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-0">
                                            <v-btn x-small dark class="primary">
                                                <v-icon small @click="exibirDetalhes(item)">mdi-magnify</v-icon>
                                            </v-btn>
                                            <v-btn x-small dark class="ml-1 red">
                                                <v-icon small @click="confirmaDelecao(item)">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:no-data>
                        <span>No momento não há tarefas nesse projeto</span>
                    </template>
                </v-data-table>
            </v-card>

            <v-card class="mt-6">
                <v-card-title>
                    Arquivos
                    <v-spacer />
                    <v-btn @click="dialogArquivoNovo = true" elevation="0" color="primary">
                        <v-icon class="pr-3">mdi-plus</v-icon>Arquivo
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="text-tuncrate">
                    <v-chip
                        @click="arquivoDownload(a.idarquivo)"
                        class="ma-2"
                        v-for="(a,i) in dadosProjeto.arquivo"
                        :key="i"
                    >
                        <v-icon left small>mdi-cloud-download</v-icon>
                        {{a.arquivoname}}
                    </v-chip>
                    <span v-if="!dadosProjeto.arquivo">Não há arquivos anexados no projeto.</span>
                </v-card-text>
            </v-card>
        </v-col>                    <!-- TABELA DE TAREFAS -->

        <v-col cols="12" lg="5" order="3" order-lg="3">
            <!--            <div>-->
            <!--                <v-progress-linear :value="(contP / totalTarefa) * 100" :color="corProgresso()" height="5" />-->
            <!--            </div> &lt;!&ndash; BARRA DE PROGRESSO &ndash;&gt;-->
            <v-card>
                <small class="float-right pa-3">Nº projeto: <strong>{{ dadosProjeto.idprojeto }}</strong></small>
                <v-card-title>{{ dadosProjeto.projeto }}</v-card-title>

                <v-divider />

                <v-card-text>
                    <v-row align="end" justify="end">
                        <v-col>
                            <div class="py-2 text-body-1 text-truncate text-md-h6">
                                <v-icon class="mr-1 mb-1">mdi-check-all</v-icon>Status
                            </div>
                            <div class="py-2 text-body-1 text-truncate text-md-h6">
                                <v-icon class="mr-1 mb-1">mdi-account-outline</v-icon>Solicitante
                            </div>
                            <div class="py-2 text-body-1 text-truncate text-md-h6">
                                <v-icon class="mr-1 mb-1">mdi-calendar-clock</v-icon>Data cadastro
                            </div>
                            <div v-if="dadosProjeto.dhconcluido" class="py-2 text-body-1 text-truncate text-md-h6">
                                <v-icon class="mr-1 mb-1">mdi-calendar-check</v-icon>Data concluído
                            </div>
                            <div v-if="dadosProjeto.dhaprovado" class="py-2 text-body-1 text-truncate text-md-h6">
                                <v-icon class="mr-1 mb-1">mdi-calendar-account</v-icon>Data aprovado
                            </div>
                        </v-col>

                        <v-col class="text-right">
                            <div class="py-2 text-body-1 text-truncate text-md-h6 d-block d-md-none">
                                <v-chip :color="dadosProjeto.status == 'Concluído' ? 'green darken-4' : 'yellow darken-4'" dark x-small class="font-weight-bold py-3 rounded-pill">{{ dadosProjeto.status }}</v-chip>
                            </div>
                            <div class="py-2 text-body-1 text-truncate text-md-h6 d-none d-md-block">
                                <v-chip :color="dadosProjeto.status == 'Concluído' ? 'green darken-4' : 'yellow darken-4'" dark class="font-weight-bold">{{ dadosProjeto.status }}</v-chip>
                            </div>
                            <div class="py-2 text-body-1 text-truncate text-md-h6">{{ dadosProjeto.usuariosolicitacao | formataTextoCurto }}</div>
                            <div v-if="dadosProjeto.dhcadastro" class="py-2 text-body-1 text-truncate text-md-h6 d-block d-md-none">{{ dadosProjeto.dhcadastro.split(" ")[0] }}</div>
                            <div class="py-2 text-body-1 text-truncate text-md-h6 d-none d-md-block">{{ dadosProjeto.dhcadastro }}</div>
                            <div v-if="dadosProjeto.dhconcluido" class="py-2 text-body-1 text-truncate text-md-h6 d-block d-md-none">{{ dadosProjeto.dhconcluido.split(" ")[0] }}</div>
                            <div v-if="dadosProjeto.dhconcluido" class="py-2 text-body-1 text-truncate text-md-h6 d-none d-md-block">{{ dadosProjeto.dhconcluido }}</div>
                            <div v-if="dadosProjeto.dhaprovado" class="py-2 text-body-1 text-truncate text-md-h6 d-block d-md-none">{{ dadosProjeto.dhaprovado.split(" ")[0] }}</div>
                            <div v-if="dadosProjeto.dhaprovado" class="py-2 text-body-1 text-truncate text-md-h6 d-none d-md-block">{{ dadosProjeto.dhaprovado }}</div>
                        </v-col>

                        <v-col cols="12" class="mb-n8">
                            <div class="text-h6 font-weight-bold">Descrição</div>
                            <v-textarea outlined rows="3" readonly :value="dadosProjeto.descricao" />
                        </v-col>

                        <v-col cols="12" class="mb-n8">
                            <v-select
                                :items="status.lista"
                                item-text="status"
                                item-value="idstatus"
                                dense
                                label="Novo status"
                                outlined
                                v-model="idstatus"
                            ></v-select>
                        </v-col>

                        <v-col>
                            <v-btn
                                class="px-3"
                                color="primary"
                                elevation="0"
                                block
                                @click="atualizarProjeto()"
                            >Atualizar</v-btn>
                            <v-btn
                                class="px-3 text-decoration-underline"
                                elevation="0"
                                color="primary"
                                block
                                text
                                to="/projeto"
                                exact
                            >Voltar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>                    <!-- DESCRIÇÕES DO PROJETO -->
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";
import InputDatePicker from "../Widgets/InputDatePicker";

export default {
    name: "ProjetoGet",
    components: {
        CardDialog,
        InputDatePicker
    },
    props: ["idprojeto"],
    data: () => ({
        dados: {},
        arquivos: [],

        totalTarefa: 0,
        dadosTarefa: [],
        dadosProjeto: {},
        tempItem: {},

        dialog: { status: false, title: "", msg: "", icon: "", iconColor: "" },
        snackbar: { status: false, timeout: 2000 },
        carregando: false,
        status: [],

        refresh: 30,
        idstatus: 2,
        usuarios: { lista: [] },
        contP: 0,

        dialogArquivoNovo: false,
        dialogAdicionar: false,
        dialogDelete: false,
        dialogDetalhe: false,
    }),

    computed: {
        ...mapState(["backendUrl", "usuario"]),
    },

    methods: {
        // corProgresso(){
        //     if(((this.contP / this.totalTarefa) * 100) < 15 ){
        //         return 'red'
        //     } else if(((this.contP / this.totalTarefa) * 100) < 30 ){
        //         return 'orange darken-2'
        //     } else if(((this.contP / this.totalTarefa) * 100) < 45 ){
        //         return 'amber'
        //     } else if(((this.contP / this.totalTarefa) * 100) < 60 ){
        //         return 'yellow'
        //     } else if(((this.contP / this.totalTarefa) * 100) < 85 ){
        //         return 'lime darken-1'
        //     } else if(((this.contP / this.totalTarefa) * 100) >= 85 ){
        //         return 'light-green darken-2'
        //     }
        // },

        get() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/get`, {
                    idprojeto: parseInt(this.idprojeto),
                })
                .then((res) => {
                    this.dadosProjeto = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },

        getTarefas() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/tarefa/listar`, {
                    idprojeto: parseInt(this.idprojeto)
                })
                .then((res) => {
                    this.carregando = false;
                    this.contP=0
                    this.totalTarefa = res.data.total
                    this.dadosTarefa = res.data.lista.sort((a, b) => a.idtarefa - b.idtarefa)
                        .map((v, i) => {
                            if( v.concluida == true){
                                this.contP++
                            }
                            return {
                                id: i + 1,
                                idtarefa: v.idtarefa,
                                tarefa: v.tarefa,
                                descricao: v.descricao,
                                usuariosolicitacao: v.usuariosolicitacao,
                                usuarioaprovacao: v.usuarioaprovacao,
                                idusuarioconclusao: v.idusuarioconclusao,
                                usuarioconclusao: v.usuarioconclusao,
                                dhcadastro: v.dhcadastro,
                                dhvalidacao: v.dhvalidacao,
                                dhconcluido: v.dhconcluido,
                                tempomedio: v.tempomedio,
                                concluida: v.concluida,
                                validada: v.validada,
                            };
                        });
                })
                .catch(() => {
                    this.carregando = false;
                });
        },

        listarStatus() {
            return axios.post(`${this.backendUrl}projeto/status`).then((res) => {
                this.status = res.data;
            });
        },

        listarUsuarios() {
            this.usuarios.lista = [];
            return axios
                .post(`${this.backendUrl}usuario/listar`, {
                    limit: 99999,
                    offset: 0,
                })
                .then((res) => {
                    if (res.data.lista != null) {
                        this.usuarios = res.data;
                    }
                });
        },

        adicionarTarefas() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/tarefa/abrir`, {
                    idprojeto: this.dadosProjeto.idprojeto,
                    tarefa: this.tempItem.tarefa,
                    descricao: this.tempItem.descricao,
                    idusuariosolicitacao: this.tempItem.idusuariosolicitacao,
                    tempomedio: this.tempItem.tempomedio,
                })
                .then((res) => {
                    if (res.data) {
                        this.snackbar.text = "Tarefa adicionada com sucesso";
                        this.snackbar.status = true;
                        this.getTarefas();
                        this.dialogAdicionar = false;
                        this.tempItem = {}
                    } else {
                        this.dialog.status = true;
                        this.dialog.title = "Erro";
                        this.dialog.msg = "Não foi possível abrir o projeto!";
                        this.dialog.icon = "mdi-alert-circle-outline";
                        this.dialog.iconColor = "red";
                        this.carregando = false;
                    }
                })
                .catch(() => {
                    this.carregando = false;
                });
        },

        atualizarTarefa(tarefa){
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/tarefa/atualizar`, {
                    idtarefa: tarefa.idtarefa,
                    concluida: tarefa.concluida,
                    validada: tarefa.validada,
                })
                .then((res) => {
                    if (res.data) {
                        this.snackbar.text = "Tarefa Atualizada com sucesso";
                        this.snackbar.status = true;
                        this.getTarefas();
                    } else {
                        this.dialog.status = true;
                        this.dialog.title = "Erro";
                        this.dialog.msg = "Não foi possível alterar o projeto!";
                        this.dialog.icon = "mdi-alert-circle-outline";
                        this.dialog.iconColor = "red";
                    }
                    this.carregando = false;
                    return this.dialog;
                })
                .catch(() => {
                    this.dialog.status = true;
                    this.dialog.title = "Erro";
                    this.dialog.msg = "Não foi possível alterar o projeto!";
                    this.dialog.icon = "mdi-alert-circle-outline";
                    this.dialog.iconColor = "red";
                    this.carregando = false;
                    return this.dialog;
                });
        },

        exibirDetalhes(tarefa){
            this.tempItem = {}
            this.tempItem = tarefa
            this.dialogDetalhe = true
        },

        atualizarProjeto() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/atualizar`, {
                    idprojeto: parseInt(this.idprojeto),
                    idstatus: parseInt(this.idstatus),
                })
                .then((res) => {
                    if (res.data) {
                        this.dialog.status = true;
                        this.dialog.title = "Projeto atualizado";
                        this.dialog.msg = "Projeto atualizado com sucesso!";
                        this.dialog.icon = "mdi-check-circle-outline";
                        this.dialog.iconColor = "success";
                        this.get();
                    } else {
                        this.dialog.status = true;
                        this.dialog.title = "Erro";
                        this.dialog.msg = "Não foi possível alterar o projeto!";
                        this.dialog.icon = "mdi-alert-circle-outline";
                        this.dialog.iconColor = "red";
                    }
                    this.carregando = false;
                    return this.dialog;
                })
                .catch(() => {
                    this.dialog.status = true;
                    this.dialog.title = "Erro";
                    this.dialog.msg = "Não foi possível alterar o projeto!";
                    this.dialog.icon = "mdi-alert-circle-outline";
                    this.dialog.iconColor = "red";
                    this.carregando = false;
                    return this.dialog;
                });
        },

        confirmaDelecao(tarefa){
            this.tempItem = {}
            this.tempItem = tarefa
            this.dialogDelete = true
        },

        deletarTarefa(tarefa) {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/tarefa/deletar`, {
                    idtarefa: parseInt(tarefa.idtarefa),
                })
                .then((res) => {
                    if (res.data) {
                        this.snackbar.text = "Tarefa excluída com sucesso";
                        this.snackbar.status = true;
                        this.getTarefas();
                    } else {
                        this.dialog.status = true;
                        this.dialog.title = "Erro";
                        this.dialog.msg = "Não foi possível excluir a tarefa!";
                        this.dialog.icon = "mdi-alert-circle-outline";
                        this.dialog.iconColor = "red";
                    }
                    this.carregando = false;
                    return this.dialog;
                })
                .catch(() => {
                    this.dialog.status = true;
                    this.dialog.title = "Erro";
                    this.dialog.msg = "Não foi possível excluir a tarefa!";
                    this.dialog.icon = "mdi-alert-circle-outline";
                    this.dialog.iconColor = "red";
                    this.carregando = false;
                    return this.dialog;
                });
        },

        arquivoNovo() {
            return axios
                .post(`${this.backendUrl}projeto/arquivo/novo`, {
                    idprojeto: parseInt(this.idprojeto),
                    arquivo: this.arquivos || null,
                })
                .then((res) => {
                    if (res.data) {
                        this.dialogArquivoNovo = false;
                        this.dialog.title = "Arquivo adicionado";
                        this.dialog.msg = "Arquivo adicionado com sucesso!";
                        this.dialog.icon = "mdi-check-circle-outline";
                        this.dialog.iconColor = "success";
                        this.dialog.status = true;
                        this.get();
                    } else {
                        this.dialogArquivoNovo = false;
                        this.dialog.title = "Erro";
                        this.dialog.msg =
                            "Não foi possível adicionar o arquivo ao projeto!";
                        this.dialog.icon = "mdi-alert-circle-outline";
                        this.dialog.iconColor = "red";
                        this.dialog.status = true;
                    }
                })
                .catch(() => {
                    this.dialogArquivoNovo = false;
                    this.dialog.title = "Erro";
                    this.dialog.msg =
                        "Não foi possível adicionar o arquivo ao projeto!";
                    this.dialog.icon = "mdi-alert-circle-outline";
                    this.dialog.iconColor = "red";
                    this.dialog.status = true;
                });
        },

        adicionarArquivo(arquivos) {
            this.carregando = true;
            this.arquivos = [];
            let a = [];
            for (let i = 0; i < arquivos.length; i++) {
                let reader = new FileReader();
                reader.readAsDataURL(arquivos[i]);
                reader.onload = function () {
                    a[i] = {
                        arquivo: reader.result.split("base64,")[1],
                        arquivoname: arquivos[i].name,
                    };
                };
            }
            this.arquivos = a;
            this.carregando = false;
        },

        arquivoDownload(idarquivo) {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}projeto/arquivo/download`, {
                    idarquivo,
                })
                .then((res) => {
                    this.downloadBase64(res.data);
                    this.carregando = false;
                });
        },

        async init() {
            // this.timerRefresh();
            await this.get();
            await this.getTarefas();
            await this.listarUsuarios();
            await this.listarStatus();
            // await this.listarRegistros(true);
        },
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
.container-scroll {
    min-height: 140px;
    max-height: 260px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}
</style>
